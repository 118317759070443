import React, { FC, useEffect } from "react";
import { Link } from "react-router-dom";

export const AboutMe: FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="bg-primary text-primary-content  ">
      <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0 pb-12">
        <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-24">
          <div>
            <h2 className="text-4xl font-semibold pt-12 pb-6 sm:text-5xl md:text-6xl">
              About me
            </h2>
            <p className="pb-3">
              Always wanted to create something useful and helpful for the
              people.{" "}
            </p>
            <h1 className="text-3xl font-semibold py-3 sm:text-4xl sm:py-4 md:text-5xl text-left">
              I am a Design enthusiast and innovative searcher
            </h1>

            <p className="py-3">
              Fear of not being good enough has held me back from applying for
              my dream job in the design world.
            </p>
            <p className="py-6"></p>
            <button className="btn btn-outline btn-secondary justify-center rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px] footer-center mb-14">
              <Link to={"about"}>Read more about me</Link>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-5 h-5"
              >
                <path
                  fillRule="evenodd"
                  d="M3 10a.75.75 0 0 1 .75-.75h10.638L10.23 5.29a.75.75 0 1 1 1.04-1.08l5.5 5.25a.75.75 0 0 1 0 1.08l-5.5 5.25a.75.75 0 1 1-1.04-1.08l4.158-3.96H3.75A.75.75 0 0 1 3 10Z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
          <div className="hidden lg:block self-center justify-self-center">
            <span className="relative flex h-14 w-14 md:h-20 md:w-20 top-8">
              <span className="relative inline-flex rounded-full h-14 w-14 md:h-20 md:w-20 bg-secondary opacity-50"></span>
            </span>
            <span className="relative flex h-14 w-14 md:h-20 md:w-20 top-8 ">
              <span className="relative inline-flex rounded-full h-14 w-14 md:h-20 md:w-20 bg-accent opacity-50"></span>
            </span>
            <span className="relative flex h-14 w-14 md:h-20 md:w-20 top-8">
              <span className="relative inline-flex rounded-full h-14 w-14 md:h-20 md:w-20 bg-pink-300 opacity-50 "></span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

import { FC } from "react";
import { Contactme } from "./contactme";
import ScrollToTop from "react-scroll-to-top";

export const Contact: FC = () => {
  return (
    <>
      <h1> </h1>
      <Contactme />

      <ScrollToTop smooth component={<p style={{ color: "#7AA874" }}>UP</p>} />
    </>
  );
};

import { FC } from "react";
import { useRouteError } from "react-router-dom";
import { Link } from "react-router-dom";
import taust from "./assets/Taust.jpg";
import { NavigationBar } from "./NavigationBar";
import { Footer } from "./Footer";

export const ErrorPage: FC = () => {
  const error = useRouteError() as any;
  console.error(error);

  return (
    <div>
      {" "}
      {/* Wrap JSX elements inside a parent element */}
      <NavigationBar />
      <div id="error-page">
        <div
          className="flex items-center justify-center min-h-screen bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${taust})`,
          }}
        >
          <div className="max-w-md mx-auto text-center bg-white bg-opacity-90 p-8 rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px]">
            <div className="text-9xl font-bold text-secondary mb-4">404</div>
            <h1 className="text-4xl font-bold text-gray-800 mb-6">
              Oops! Page Not Found
            </h1>
            <p className="text-lg text-gray-600 mb-8">
              The page you're looking for seems to have gone on a little
              adventure. Don't worry, we'll help you find your way back home.
            </p>
            <Link to={"../"}>
              <button className="btn btn-outline btn-secondary justify-center rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px] footer-center mb-14">
                Go Back Home
              </button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

import { FC, useEffect } from "react";
import { CircaDia } from "./CircaDia";
import circa1 from "../assets/circa.jpg";
import circa2 from "../assets/rutm.jpg";
import circa3 from "../assets/galaxy-gear-4-470x310@2x.jpg";
import circa4 from "../assets/watch.gif";
import circa5 from "../assets/CircaDia.jpg";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";

export const CircaDiaPage: FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <CircaDia maxNrOfSlides={0} />
      <div className="bg-accent text-accent-content">
        <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0 py-6 lg:py-12">
          <h1> </h1>
          <h3 className="text-2xl font-semibold first-letter pt-12 pb-6 sm:text-3xl">
            ABSTRACT
          </h3>
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-10 pb-6">
            <div>
              <p className="pb-3">
                The ESA_Lab Demonstrator Project IGLUNA mission aims to create a
                human habitat on the Moon using ice from nearby craters,
                focusing on sustaining astronauts' well-being and performance in
                this dangerous environment.
              </p>
            </div>
            <div>
              <p className="pb-3">
                Circa Dia is a{" "}
                <span className="font-bold">
                  wearable, which by measuring vital signs from the human body,
                </span>{" "}
                can{" "}
                <span className="font-bold">
                  give an insight into a person’s individual fatigue levels and
                  night/ day cycle.
                </span>{" "}
                With that, we give them an opportunity for astronauts{" "}
                <span className="font-bold">to make informed decisions</span>{" "}
                about their daily schedule and through that{" "}
                <span className="font-bold">
                  improve their well-being and performance.
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className="carousel-item contain-fill w-full flex-col-reverse">
          <img src={circa5} alt="CircaDia_Final_Project" />
        </div>
      </div>
      <div className="bg-primary text-primary-content whitespace-normal">
        <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0 pb-12">
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-10 pt-6 pb-6">
            <div>
              <h3 className="text-2xl font-semibold first-letter pt-12 pb-6 sm:text-3xl">
                PROJECT BACKGROUND
              </h3>
              <p className="pb-3">
                The purpose of ESA_Lab Demonstrator Project IGLUNA mission is to
                investigate a novel approach for realizing a human habitat at
                the Moon, where the habitat is directly built in ice that is
                present in numerous craters near the poles.
              </p>
              <p className="pb-3">
                Future long-duration missions to the Moon force us to find
                solutions how to sustain astronaut’s well-being and high
                performance levels in this strange and dangerous environment,
                where human error or can have highly serious consequences for
                all stakeholders.
              </p>
            </div>
            <div>
              <h3 className="text-2xl font-semibold first-letter pt-12 pb-6 sm:text-3xl">
                CREDITS
              </h3>
              <p className="pb-3">
                The project's success was largely due to my extensive research,
                including exploring prototypes and understanding astronauts'
                daily challenges. This deep understanding shaped the project's
                direction and led to the development of impactful solutions.
              </p>
              <p className="pt-3 lg:pt-6 pb-3 font-bold">
                {" "}
                Learning opportunities
              </p>
              <p className="pb-3">
                Research, Prototyping, Schedule management, Presenting, Team
                management
              </p>
            </div>
          </div>
        </div>
        <div className="carousel w-full flex lg:h-96 lg:items-end">
          <img src={circa1} alt="astronauts" />
        </div>
        <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0">
          <h3 className="text-2xl font-semibold first-letter pt-18 pb-6 sm:text-3xl">
            RESEARCH OBJECTIVES
          </h3>
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-10 pb-6">
            <div>
              <p className="pb-3">
                Currently, humans regularly go on short-term space missions on
                the ISS, where astronauts’ daily lives are micromanaged
                by Mission Control. They work, sleep, and eat when they are
                being told, regardless of how they might feel.
              </p>
            </div>
            <div>
              <p className="pb-3">
                If astronauts’ individual stress and fatigue levels are not
                taken into account while composing their schedules, it lowers
                their well-being and quality of life. Not to mention,
                it increases the probability of human error risk while
                conducting highly demanding tasks, whose failure can have
                devastating consequences.
              </p>
            </div>
          </div>
        </div>
        <div className=" container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0 pb-12">
          <div className="w-full">
            <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-6 mt-6 mb-6">
              <div className="w-full mt-6 sm:mb-0">
                <div className="relative h-full ml-0 mr-0 sm:mr-10 ">
                  <span className="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-accent rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px]"></span>
                  <div className="relative h-full p-5 bg-primary border-2 rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px] border-accent">
                    <p className="mb-2 text-primary-content text-center">
                      “Two of the most critical issues are{" "}
                      <span className="font-bold text-wrap  bg-gradient-to-r from-blue-600 via-green-500 to-indigo-400 inline-block text-transparent bg-clip-text">
                        the radiation exposure beyond low Earth orbit and the
                        psychosocial effects of confinement and isolation,”
                      </span>{" "}
                      says Carol Scott-Conner at the University of Iowa, chair
                      of the committee behind the report. She calls them
                      “potential showstoppers” that could cause missions to
                      fail”.
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full mt-6 sm:mb-0">
                <div className="relative h-full ml-0 mr-0 sm:mr-10 ">
                  <span className="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-accent rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px]"></span>
                  <div className="relative h-full p-5 bg-primary border-2 rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px] border-accent">
                    <p className="mb-2 text-primary-content text-center">
                      “The human part of a Mars mission is just as critical as
                      the technological part.{" "}
                      <span className="font-bold text-wrap  bg-gradient-to-r from-blue-600 via-green-500 to-indigo-400 inline-block text-transparent bg-clip-text">
                        If the human part breaks, it’s just as disastrous as if
                        the rocket blows up”
                      </span>
                    </p>
                    <p className="pt-3 lg:pt-6">
                      Article by Nathaniel P. Morris “Mental Health in Outer
                      Space”
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full mt-6 sm:mb-0">
                <div className="relative h-full ml-0 mr-0 sm:mr-10 ">
                  <span className="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-accent rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px]"></span>
                  <div className="relative h-full p-5 bg-primary border-2 rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px] border-accent">
                    <p className="mb-2 text-primary-content text-center">
                      “Astronauts might want to{" "}
                      <span className="font-bold text-wrap  bg-gradient-to-r from-blue-600 via-green-500 to-indigo-400 inline-block text-transparent bg-clip-text">
                        hide mental health issues during screenings
                      </span>{" "}
                      in order to avoid professional consequences”
                      <p className="pt-3 lg:pt-6">
                        Kim Binsted, Principal Investigator, HI-SEAS
                      </p>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container relative flex flex-col justify-between h-full max-w-3xl px-10 mx-auto xl:px-0">
          <div className="grid grid-cols-1 lg:grid-cols-3">
            <div>
              <div className="pt-3 font-bold">Natural environment</div>
              <br />- No atmosphere
              <br />- Moon dust
              <br />- Space radiation
              <br />- Microgravity (1/6th of Earth’s)
              <br />- Perpetually in the dark
              <br />- Extremely coldh’s)
              <br />- No nature
            </div>
            <div>
              <div className="pt-3 font-bold">Psychological issues</div>
              <br />- Stress
              <br />- Depression
              <br />- Feeling isolated
              <br />- Sleep disorders
              <br />- Loneliness
              <br />- Homesickness
              <br />- Hallucinations
              <br />- Anxiety
              <br />- Psychosis
              <br />- etc.
            </div>
            <div>
              <div className="pt-3 font-bold">Physical issues</div>
              <br />- Muscle atrophy 
              <br />- Bone loss
              <br />- Cardiovascular diseases
              <br />- Impaired immune function
              <br />- Problems with spine
              <br />- Vision problems 
              <br />- Bronchitis
              <br />- Cancer
              <br />- etc.
            </div>
            <div className="pb-12"></div>
          </div>
        </div>
      </div>
      <div className="bg-primary-content test-primary text-center my-24 mx-24 lg:my-48 lg:mx-60">
        <h2 className="pt-8 text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl font-semibold lg:py-12">
          HOW TO MANAGE PHYSICAL AND MENTAL HEALTH?
        </h2>
      </div>
      <div className="bg-primary text-primary-content">
        <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0">
          <h3 className="text-2xl font-semibold first-letter pt-18 pb-6 sm:text-3xl">
            CONCEPT
          </h3>
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-10 pb-6">
            <div>
              <p className="pb-3">
                A wearable device is being developed to measure astronauts'
                movements, body temperature, and heart rate, providing insight
                into
                <span className="font-bold">
                  {" "}
                  their individual circadian rhythm.{" "}
                </span>
              </p>
              <p className="pb-3">
                <span className="font-bold">
                  {" "}
                  Circadian rhythm is the inner clock of all living beings,
                  regulating functions like behavior, hormone levels, sleep,
                  body temperature, and metabolism. This information helps
                  astronauts plan their work, exercise, and sleep schedules,
                  minimizing human error.
                </span>
              </p>
            </div>
            <div className="w-full h-full relative">
              <img
                className="w-full h-full object-contain"
                src={circa2}
                alt="Circadian_Rhythm_explenation"
                h-auto
                w-auto
                justify-content
              />
            </div>
          </div>
          <h3 className="text-2xl font-semibold first-letter pt-12 pb-6 sm:text-3xl">
            PROTOTYPING
          </h3>
          <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-10">
            <div className="text-box">
              <p className="pb-3">
                Our initial idea was to{" "}
                <span className="font-bold">
                  {" "}
                  gently and non-intrusively draw the astronaut's attention back
                  to their body and gain awareness of their mental and physical
                  state{" "}
                </span>{" "}
                when the measuring device detects changes in their circadian
                rhythms. To understand how that could be done, we explored
                different existing measuring devices, interfaces, and their
                combinations:
              </p>

              <span className="pb-3">
                <br />- Smart clothing
                <br />- Patch/ Band-Aid implantant
                <br />- Wrist watch
                <br />- Temporary tattoo
              </span>
            </div>
            <div className="w-full h-full relative">
              <img
                className="w-full h-full object-contain"
                src={circa3}
                alt="Galaxy_Gear_Samsung_Watch"
                h-auto
                w-auto
                justify-content
              />
            </div>
            <div className="w-full h-full relative">
              <img
                className="w-full h-full object-contain"
                src={circa4}
                alt="Smart_Watch"
                h-auto
                w-auto
                justify-content
              />
            </div>
          </div>
        </div>
        <div className="box-content bg-neutral text-primary-content">
          <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0 py-6 lg:py-12">
            <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-10 pt-6 pb-6">
              <div className="pt-5 pb-3">
                The analysis of various measuring devices and interfaces
                revealed that feedback like vibration, heat, or contraction is
                too abstract for astronauts to gain awareness of their state.
              </div>
              <div className="pb-3 ">
                <br />+ Measuring device and interface 2in1
                <br />+ Familiar
                <br />+ Easily removable
                <br />+ Lightweight and small (keeps the transport costs low)
                <br />+ Easily removable
                <br />+ Easy setup
                <br />+ Easily removable
                <br />+ Relatively low maintenance and hassle-free
                <br />+ Non-intrusive
              </div>
            </div>
          </div>
        </div>
        <div className="bg-primary text-primary-content">
          <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0 py-6 lg:py-12">
            <h3 className="text-2xl font-semibold first-letter pt-12 pb-6 sm:text-3xl">
              SCHEDULE MANAGEMENT
            </h3>
            <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-10 pb-6">
              <div>
                <p className="pb-3">
                  This is what they will see on the screen of their work
                  devices.
                </p>
                <p className="pb-3">
                  The estimated circadian rhythm will be processed into mental
                  and physical activity levels, and the same data processing
                  will also receive mission control tasks.
                </p>
                <p className="pb-3">
                  Fixed events and tasks are created based on crew rhythms, with
                  astronauts managing tasks on their personal schedules, guided
                  by the circadian rhythm, to increase efficiency.
                </p>
              </div>
              <div>
                <video className="h-full w-full rounded-lg" controls autoPlay>
                  <source src="/video/pad.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-accent text-accent-content">
          <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0 py-6 lg:py-12">
            <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-10 pt-6 pb-6">
              <video className="h-full w-full rounded-lg" controls autoPlay>
                <source src="/video/kell_1.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div className="text-box">
                <h3 className="text-2xl font-semibold first-letter pt-12 pb-6 sm:text-3xl">
                  MEASURING AND COLLECTING DATA
                </h3>
                <p className="pb-3">
                  A wristwatch measuring vital signs will provide easy access to
                  an astronaut's schedule and enable on-the-go scheduling
                  changes.
                </p>
                <p className="pb-3">
                  The circadian levels are also viewable here. As we cannot
                  predict everything far into the future, we analyse their heart
                  rate and acceleration to determine their fatigue levels.
                </p>
              </div>
            </div>
          </div>{" "}
        </div>
        <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0 py-6 lg:py-12">
          <h3 className="text-2xl font-semibold first-letter pt-6 pb-6 sm:text-3xl">
            PREVENTING MISTAKES
          </h3>
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-10 pb-6">
            <div>
              <p className="pb-3 ">
                Astronauts can now be notified in advance about potential
                fatigue issues that may affect their alertness and ability to
                perform high-risk tasks like moonwalk.
              </p>
              <p className="pb-3 ">
                Notifying astronauts of fatigue after a task can reduce the risk
                of costly mistakes and facilitate recovery for astronauts.
              </p>
              <p className="pb-3">
                Earth operates daily by moving things based on our feelings and
                making schedules based on our body and mind's input.
              </p>
            </div>
            <video className="h-full w-full rounded-lg" controls autoPlay>
              <source src="/video/kell_2.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
      <div className="box-content bg-neutral text-primary-content">
        <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0 py-6 lg:py-12">
          <h3 className="text-2xl font-semibold first-letter pt-6 pb-6 sm:text-3xl">
            THE BENEFITS
          </h3>
          <p className="pb-3">
            It’s in mission control’s interest to{" "}
            <span className="font-bold">
              {" "}
              maximise the efficiency of the missions.
            </span>{" "}
            When harmonious schedule management enhances the performance of the
            astronauts and lowers risks of human error while giving the crew
            more control of their own day - everybody wins!
          </p>
          <p className="pb-3">
            Fatigue is responsible for up to a quarter of serious or fatal
            accidents on the road when driving, of course, we should take that
            into account when planning the schedules of our astronauts - the
            stakes up in space are so much higher.
          </p>
        </div>
      </div>

      <div className="box-content bg-secondary text-secondary-content ">
        <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0 py-6 lg:py-6">
          <h3 className="text-2xl font-semibold first-letter pt-12 pb-6 sm:text-3xl">
            CONCLUSION
          </h3>
          <p className="pb-3">
            On Earth we move things around in our schedule according to how we
            feel every day, and ideally make schedules listening to what our
            body and mind tells us.
          </p>
          <p className="pb-3">
            It’s in Mission Control’s interest to maximise the efficiency of the
            missions. When harmonious schedule management enhances the
            performance of the astronauts and lowers risks of human error while
            giving crew more control of their own day - everybody wins.
          </p>
          <p className="pb-3">
            Fatigue is responsible for up to a quarter of serious or fatal
            accidents on the road when driving. We should take that into account
            when planning the schedules of our astronauts - the stakes up in
            Space are much higher!
          </p>
          <p className="pb-3 font-bold">
            Erko Pettai, Grete Pärtel (Taimre), Liis Saare, Helen Susan Selirand
          </p>
        </div>
      </div>
      <div className="py-12 text-center">
        <button className="btn btn-outline btn-secondary justify-center rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px]">
          <Link to={"../VeleV"}>Next Case Study</Link>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-5 h-5"
          >
            <path
              fillRule="evenodd"
              d="M3 10a.75.75 0 0 1 .75-.75h10.638L10.23 5.29a.75.75 0 1 1 1.04-1.08l5.5 5.25a.75.75 0 0 1 0 1.08l-5.5 5.25a.75.75 0 1 1-1.04-1.08l4.158-3.96H3.75A.75.75 0 0 1 3 10Z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      <ScrollToTop smooth component={<p style={{ color: "#7AA874" }}>UP</p>} />
    </>
  );
};

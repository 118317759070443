import React, { FC } from "react";
import { Aigor } from "../case_studies/Aigor";
import { Velev } from "../case_studies/Velev";
import { CircaDia } from "../case_studies/CircaDia";

export const CaseStudies: FC = () => {
  const maxNrOfSlides = 0;
  return (
    <>
      <div className="bg-primary">
        <Velev maxNrOfSlides={maxNrOfSlides} />
        <div className="mb-12"></div>
        <CircaDia maxNrOfSlides={maxNrOfSlides} />
        <div className="mb-12"></div>
        <Aigor maxNrOfSlides={maxNrOfSlides} />
      </div>
    </>
  );
};

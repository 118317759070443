import { Outlet } from "react-router-dom";
import { Footer } from "./Footer";
import { NavigationBar } from "./NavigationBar";

const Layout = () => {
  return (
    <div data-theme="GretePorfolio">
      <NavigationBar />
      <Outlet />
      <Footer />
    </div>
  );
};

export default Layout;

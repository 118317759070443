import { FC } from "react";

export const Education: FC = () => {
  return (
    <>
      <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0 pb-12">
        <h3 className="text-2xl font-semibold pt-12 pb-12 sm:text-3xl ">
          EDUCATION
        </h3>

        <ul className="mx-8 timeline timeline-snap-icon max-md:timeline-compact timeline-vertical">
          <li>
            <TimelineMiddle />
            <div className="timeline-start md:text-end mb-10">
              <time className="text-4xl md:text-5xl">2023-2024</time>
              <div className="text-2xl">EKA</div>
              <div className="text-lg text-secondary font-bold">Web design</div>
              Skills: Figma, HTML, CSS, user research, React, Tailwind
            </div>
            <hr />
          </li>
          <li>
            <hr />
            <TimelineMiddle />
            <div className="timeline-end md:mb-10">
              <time className="text-4xl md:text-5xl">2017-20..</time>
              <div className="text-2xl">TalTech and EKA</div>
              <div className="text-lg text-secondary font-bold">
                Design and Technology Futures
              </div>
              Skills: team work, design thinking methods, User interviews,
              journey mapping, prototyping, testing, presenting. Adobe programs
            </div>
            <hr />
          </li>
          <li>
            <hr />
            <TimelineMiddle />
            <div className="timeline-start md:text-end mb-10">
              <time className="text-4xl md:text-5xl">2013-2014</time>
              <div className="text-2xl">University of Beira Interior</div>
              <div className="text-lg text-secondary font-bold">
                Technical Design and Technology of Apparel
              </div>
              Skills: team work, kaledo, Lectra CAD/CAM, sewing, business,
              production
            </div>
            <hr />
          </li>
          <li>
            <hr />
            <TimelineMiddle />
            <div className="timeline-end">
              <time className="text-4xl md:text-5xl">2010-2016</time>
              <div className="text-2xl">TTK University of Applied Sciences</div>
              <div className="text-lg text-secondary font-bold">
                Fashion/Apparel Design
              </div>
              Skills: team work, kaledo, Lectra CAD/CAM, sewing, Adobe programs
            </div>
            <hr />
          </li>
          <li>
            <hr />
            <div className="timeline-middle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5"
              >
                <path />
              </svg>
            </div>
          </li>
        </ul>
        <p className="pb-12"></p>
      </div>
    </>
  );
};

const TimelineMiddle = () => (
  <div className="timeline-middle">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className="h-5 w-5"
    >
      <path
        fillRule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
        clipRule="evenodd"
      />
    </svg>
  </div>
);

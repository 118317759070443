import { FC, useEffect } from "react";
import { Aigor } from "./Aigor";
import AigorLogo from "../assets/workspace_1.svg";
import aigor3 from "../assets/aigor3.png";
import aigor4 from "../assets/designsystem.png";
import aigor5 from "../assets/Aigorscreen.png";
import aigor6 from "../assets/AigorFinal.png";
import aigor7 from "../assets/Aigor.png";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";

export const AigorPage: FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <h1> </h1>
      <Aigor maxNrOfSlides={0} />
      <div className="bg-accent text-accent-content">
        <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0 py-6 lg:py-12">
          <h3 className="text-2xl font-semibold first-letter pt-12 pb-6 sm:text-3xl">
            ABSTRACT
          </h3>
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-10">
            <div>
              <p className="pb-3">
                Demography significantly influences labor force growth and
                social trends. The future labor force will be older, more
                diverse, and more women. As technology advances, older
                generations may face challenges in the workplace. Employers aim
                to bridge this gap by introducing interactive methods beyond
                physical space.
              </p>
            </div>
            <div>
              <p className="pb-3">
                <span className="font-bold">
                  AIGOR is a virtual space for employees to interact with one
                  another by creating an extension of themselves through the
                  integration of artificial intelligence.{" "}
                </span>
                The platform will serve as an enhanced communication tool,
                enabling employees to share expertise, resources, and networks
                within their company. It will transform your digital workspace
                into an interaction simulator, allowing you to build and grow
                your network.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="carousel-item contain-fill w-full flex-col-reverse">
        <img src={aigor7} alt="Aigor_Final_Project" />
      </div>
      <div className="bg-primary text-primary-content whitespace-normal">
        <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0 pb-12">
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-10 pt-6 pb-6">
            <div>
              <h3 className="text-2xl font-semibold first-letter pt-12 pb-6 sm:text-3xl">
                PROJECT BACKGROUND
              </h3>
              <p className="pb-3">
                This project explores the impact of rapid technological
                advancements on work habits and the aging population. It
                explores the concept of 'work 2.0', a new way of working that
                aims to adapt to the changing world. The project explores how
                workplaces can adapt to the aging workforce, who are still
                active in daily tasks, and how these changes can be harnessed
                for better human lives. The project aims to explore the future
                of work in the face of these changes.
              </p>
            </div>
            <div>
              <h3 className="text-2xl font-semibold first-letter pt-12 pb-6 sm:text-3xl">
                CREDITS
              </h3>
              <p className="pb-3">
                The project involved extensive research, literature review,
                product analysis, surveys, interviews, and visual design using
                Adobe Systems, with my role extending to the creation of
                visuals.
              </p>
              <p className="pt-3 lg:pt-6 pb-3 font-bold">
                Learning opportunities
              </p>
              <p className="pb-3">
                Research, Prototyping, Schedule management, Presenting, Team
                management, Video Making, Visual Design.
              </p>
            </div>
          </div>
        </div>
        <div className="carousel w-full flex lg:h-96 lg:items-end">
          <img src={AigorLogo} alt="workspace" />
        </div>
        <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0 pb-6">
          <h3 className="text-2xl font-semibold first-letter pt-18 pb-6 sm:text-3xl">
            RESEARCH OBJECTIVES
          </h3>
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-10">
            <div>
              <p className="pb-3">
                According to the assumption that by 2035,{" "}
                <span className="font-bold">
                  one in five people worldwide will be 65 or older.
                </span>
              </p>
              <p className="pb-3">
                By 2030, <span className="font-bold">55 countries</span> are
                expected to see their{" "}
                <span className="font-bold">
                  65 and older populations at least 20 percent of their total.
                </span>
              </p>
            </div>
            <div>
              <p>
                All these statistics show that{" "}
                <span className="font-bold">the workforce is aging,</span> which
                means the <span className="font-bold">work environment </span>{" "}
                and <span className="font-bold">workforce</span> should{" "}
                <span className="font-bold">adapt to the changes.</span> Right
                now we have more and more work environments with people in
                different age groups, which creates{" "}
                <span className="font-bold">
                  demographic shift and generation overlapping.
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="container relative flex flex-col justify-between h-full max-w-3xl px-10 mx-auto xl:px-0">
          <div className="grid grid-cols-1 lg:grid-cols-2">
            <div className="pb-6">
              <div className="font-bold">Eustress</div>
              <br />- Motivates, focuses energy
              <br />- Is short-term
              <br />- Is perceived as within our coping abilities
              <br />- Feels exciting
              <br />- Improves performance
              <br />- etc.
            </div>
            <div className="pb-3">
              <div className="font-bold">Distress</div>
              <br />- Causes anxiety or concern
              <br />- Can be short- or long-term
              <br />- Is perceived as outside of our coping abilities
              <br />- Feels unpleasant
              <br />- Decreases performance
              <br />- Can lead to mental and physical problems
              <br />- etc.
            </div>
          </div>
        </div>
        <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0 pb-6">
          <h3 className="text-2xl font-semibold first-letter pt-12 pb-6 sm:text-3xl">
            RESEARCH FINDINGS
          </h3>
          <div>
            <p className="pb-3">
              The research methodology, which included interviews, surveys, and
              observations, likely provided a comprehensive understanding of
              users' perspectives and behaviors, offering valuable insights for
              the study.
            </p>
            <div>
              <div className="font-bold">Survey findings:</div>
              <br />- Miscommunication
              <br />- Blamed without fault
              <br />- Seniority vs competence dispute
              <br />- Lack of skills
              <br />- Age ≠ Knowledge
              <br />- Difficult Work Environment
            </div>
          </div>
        </div>

        <div className=" container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0 pb-12">
          <div className="w-full">
            <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-6 mt-6 mb-6">
              <div className="w-full mt-6 sm:mb-0">
                <div className="relative h-full ml-0 mr-0 sm:mr-10 ">
                  <span className="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-accent rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px]"></span>
                  <div className="relative h-full p-5 bg-primary border-2 rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px] border-accent">
                    <p className="mb-2 text-primary-content text-center">
                      <span className="pb-6 font-bold text-wrap bg-gradient-to-r from-blue-600 via-green-500 to-indigo-400 inline-block text-transparent bg-clip-text">
                        “Younger people value less experience and rush into
                        decision making.”{" "}
                      </span>
                      <span>- Female, 46-55</span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full mt-6 sm:mb-0">
                <div className="relative h-full ml-0 mr-0 sm:mr-10 ">
                  <span className="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-accent rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px]"></span>
                  <div className="relative h-full p-5 bg-primary border-2 rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px] border-accent">
                    <p className="mb-2 text-primary-content text-center">
                      <span>
                        “Seniority vs competence dispute. I was told to do
                        things in a{" "}
                      </span>
                      <span className="font-bold text-wrap  bg-gradient-to-r from-blue-600 via-green-500 to-indigo-400 inline-block text-transparent bg-clip-text">
                        {" "}
                        very inefficient way
                      </span>{" "}
                      because that's how things have always been done. I just
                      ignored the seniors and did things with{" "}
                      <span className="font-bold text-wrap bg-gradient-to-r from-blue-600 via-green-500 to-indigo-400 inline-block text-transparent bg-clip-text">
                        {" "}
                        modern tools more efficiently.{" "}
                      </span>{" "}
                      When you do a week's worth of work in one day there's
                      really no more disputes.” - Male, 26-35
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full mt-6 sm:mb-0">
                <div className="relative h-full ml-0 mr-0 sm:mr-10 ">
                  <span className="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-accent rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px]"></span>
                  <div className="relative h-full p-5 bg-primary border-2 rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px] border-accent">
                    <p className="mb-2 text-primary-content text-center">
                      <span className="font-bold text-wrap  bg-gradient-to-r from-blue-600 via-green-500 to-indigo-400 inline-block text-transparent bg-clip-text">
                        Different attitudes to work, people, work culture and
                        habits are different.
                      </span>{" "}
                      For example, older people tend to communication from the
                      top down, while young people like to be{" "}
                      <span className="font-bold text-wrap bg-gradient-to-r from-blue-600 via-green-500 to-indigo-400 inline-block text-transparent bg-clip-text">
                        {" "}
                        more equal in terms of communication.
                      </span>
                      ” - Female, 26-35
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-primary-content test-primary text-center my-24 mx-24 lg:my-48 lg:mx-60">
        <h2 className="pt-8 text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl font-semibold lg:py-12">
          HOW TO RECUSE GENERATION GAP CONFLICTS RELATED TO DO COMMUNICATION?
        </h2>
      </div>
      <div className="bg-primary text-primary-content">
        <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0 pb-12">
          <h3 className="text-2xl font-semibold first-letter pt-18 pb-6 sm:text-3xl">
            CONCEPT
          </h3>
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-10 pb-6">
            <div>
              <p className="pb-3">
                <span className="font-bold">
                  {" "}
                  The platform aims to enhance communication in a
                  multigenerational workplace, reducing misunderstandings and
                  awkwardness, by allowing real-life scenarios to be tested
                  before real-life interactions.
                </span>
              </p>
              <p className="pb-3">
                The platform provides employee schedules and preferences,
                reducing misunderstandings and stress due to monotonous
                workloads, while also fostering collaboration, strengths
                building, and network improvement among employees.
              </p>
              <p className="pb-3">
                Improve peer interaction with the use of a digital extension of
                yourself as a representative.
              </p>
            </div>
            <div className="w-full h-full relative">
              <img
                className="w-full h-full object-contain"
                src={aigor3}
                alt="self-learning-interaction-simulator"
                h-auto
                w-auto
                justify-content
              />
            </div>
          </div>
        </div>
      </div>
      <div className="box-content bg-accent text-secondary-content text-center text-pretty">
        <div className="mx-4 pb-3 md:py-5 sm:mx-20 md:mx-40">
          <h3 className="text-2xl font-semibold first-letter pt-12 pb-6 sm:text-3xl">
            THE IDEA FINALIZATION
          </h3>
          <div className="pb-3 ">
            The design concept directed the team towards a solution that will
            suit two generations with the common need for more fluidity in
            interaction. A method that is able to adapt according to each one’s
            needs and preferences.
          </div>
          <div className="font-bold ">How to do it?</div>
          <br /> Artificial intelligence
          <br /> Machine learning
          <br /> data collection
          <br /> Data input
        </div>
        <div className="pb-12 "></div>
      </div>
      <div className="bg-primary text-primary-content ">
        <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0 lg:pb-6">
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-10 pt-6 pb-6">
            <div>
              <img
                className="w-full h-full object-contain lg:pt-12"
                src={aigor4}
                alt="Aigor_design_system"
                h-auto
                w-auto
                justify-content
              />
            </div>
            <div>
              <h3 className="text-2xl font-semibold first-letter pt-12 pb-6 sm:text-3xl">
                DESIGN SYSTEM
              </h3>
              <p className="pb-3">
                The user will create an AIGOR that will represent them in the
                virtual space. This will create an opportunity not only to
                network but also review one’s actions made throughout the
                workday, which will be a primary source of feedback.
              </p>
              <p className="pb-3">
                AIGOR will input data at inception and handle information
                sharing through machine learning. Interactions are recorded for
                system users and catalogued for reference, with suggestions made
                available to users.
              </p>
            </div>
          </div>
        </div>
        <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0">
          <h3 className="text-2xl font-semibold first-letter pt-12 pb-6 sm:text-3xl">
            FINAL SOLUTION SYSTEM
          </h3>
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-10 pb-6">
            <div className="pb-3">
              AIGOR is a virtual workspace that integrates artificial
              intelligence to allow employees to interact and share expertise,
              resources, and networks within their company. This enhanced
              communication platform breaks social barriers and allows employees
              to build and grow their network within a single click.
            </div>
            <div>
              <img
                className="w-full h-full object-contain"
                src={aigor5}
                alt="destop_screen_Aigor"
                h-auto
                w-auto
                justify-content
              />
            </div>
          </div>
        </div>
        <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0 pb-12">
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-10 pt-6 pb-6">
            <div>
              <img
                className="w-full h-full object-contain"
                src={aigor6}
                alt="destop_screen_Aigor"
                h-auto
                w-auto
                justify-content
              />
            </div>
            <div>
              <h3 className="text-2xl font-semibold first-letter pt-18 pb-6 sm:text-3xl">
                THE IDEA FINALIZATION
              </h3>
              <p className="pb-3 font-bold">
                A real-time digital space interaction:
              </p>
              <p className="pb-3">
                A virtual space that acts as a secondary workspace that is
                generated through the actions of the employees and capacity is
                based on machine learning. Therefore it requires a period of
                integration before reaching its full capacity.
              </p>
              <p className="pb-3 font-bold">Increased productivity:</p>
              <p className="pb-3">
                The simulation will allow employees to keep track of their work
                as well as their network.
              </p>
              <p className="pb-3 font-bold">
                Unfiltered communication experience:
              </p>
              <p className="pb-3">
                The system will operate beyond preconceptions about social
                stereotypes such as age.
              </p>
              <p className="pb-3 font-bold">
                An opportunity to build trust in a team:
              </p>
              <p className="pb-3">
                More opportunities to work with colleagues and learn from one
                another. By building a rapport over a period of time through
                simulation will create limited opportunities for
                misunderstandings that will lead with workplace conflicts.
              </p>
            </div>
          </div>
        </div>
        <header className="relative flex items-center justify-center overflow-hidden">
          <video className=" w-full rounded-lg" controls autoPlay muted>
            <source src="/video/Aigor.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </header>
      </div>
      <div className="box-content bg-secondary text-secondary-content ">
        <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0 pb-12">
          <h3 className="text-2xl font-semibold first-letter pt-18 pb-6 sm:text-3xl">
            CONCLUSION
          </h3>
          <p className="pb-3">
            Our objective was to propose a solution for to solve communication
            issue between different groups of people.
          </p>
          <p className="pb-3">
            Future learning points: Some ideas are not good ideas. Also, need
            work with visuals, but because it is a team project, I did not
            change anything.
          </p>

          <p className="pb-3 font-bold">
            Dinukshie Nirmanie Gunaratne, Juan Francisco Balcazar, Grete
            Pärtel(Taimre)
          </p>
        </div>
      </div>
      <div className="py-12 text-center">
        <button className="btn btn-outline btn-secondary justify-center rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px]">
          <Link to={"../CircaDia"}>Next Case Study</Link>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-5 h-5"
          >
            <path
              fillRule="evenodd"
              d="M3 10a.75.75 0 0 1 .75-.75h10.638L10.23 5.29a.75.75 0 1 1 1.04-1.08l5.5 5.25a.75.75 0 0 1 0 1.08l-5.5 5.25a.75.75 0 1 1-1.04-1.08l4.158-3.96H3.75A.75.75 0 0 1 3 10Z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      <ScrollToTop smooth component={<p style={{ color: "#7AA874" }}>UP</p>} />
    </>
  );
};

import { FC } from "react";
import { CaseStudyBase } from "../landing/CaseStudyBase";
import circaDia from "../assets/circa-dia.svg";
interface Props {
  maxNrOfSlides?: number;
}
export const CircaDia: FC<Props> = ({ maxNrOfSlides }) => {
  return (
    <CaseStudyBase
      heading="CircaDia"
      bgImage={circaDia}
      slideId={2}
      mxNrOfSlides={maxNrOfSlides ?? 3}
      navigation="circadia"
    >
      <span className="font-bold">
        wearable, which by measuring vital signs from human body,
      </span>{" "}
      can give an insight to person’s individual fatigue levels and night/ day
      cycle.
    </CaseStudyBase>
  );
};

import React, { FC } from "react";

interface IntroductionProps {}
export const Introduction: FC<IntroductionProps> = () => {
  return (
    <div className="bg-primary bg-gradient-to-b from-gray-950 from-75%  to-transparent text-center">
      <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0 pb-12">
        <p className="pt-28 lg:pt-36  md:text-2xl xl:text-3xl">Hi, I'm</p>
        <h1 className=" pt-8 text-4xl md:text-5xl xl:text-6xl 2xl:text-8xl font-semibold lg:pt-20 text-wrap ">
          Grete Taimre
        </h1>
        <div className="flex justify-center items-center">
          <span className="relative flex h-14 w-14 md:h-20 md:w-20 top-12">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-secondary opacity-25"></span>
            <span className="relative inline-flex rounded-full h-14 w-14 md:h-20 md:w-20 bg-secondary"></span>
          </span>
          <span className="relative flex h-14 w-14 md:h-20 md:w-20 top-12 ">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-accent opacity-25"></span>
            <span className="relative inline-flex rounded-full h-14 w-14 md:h-20 md:w-20 bg-accent"></span>
          </span>
          <span className="relative flex h-14 w-14 md:h-20 md:w-20 top-12">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-pink-300 opacity-25"></span>
            <span className="relative inline-flex rounded-full h-14 w-14 md:h-20 md:w-20 bg-pink-300 "></span>
          </span>
        </div>
        <div className="flex-grow-0 justify-center text-center pt-24 pb-36 lg:pb-60 md:text-2xl xl:text-3xl"></div>
      </div>
    </div>
  );
};

import { FC, useEffect } from "react";
import { Velev } from "./Velev";
import velev1 from "../assets/emile-seguin-211406.jpg";
import velev2 from "../assets/velev2.png";
import velev3 from "../assets/lastmile.png";
import velev4 from "../assets/familyroute.png";
import velev5 from "../assets/concept1.png";
import velev6 from "../assets/alexelatree.png";
import velev7 from "../assets/fhj (2).jpg";
import velev8 from "../assets/firstproto.png";
import velev9 from "../assets/untitled.14.jpg";
import velev10 from "../assets/untitled.36.jpg";
import velev11 from "../assets/untitled.48.jpg";
import velev13 from "../assets/Velev ümar.76 (1).jpg";
import velev14 from "../assets/Rula kinni.91.jpg";
import velev15 from "../assets/ww.jpg";
import velev16 from "../assets/show.jpg";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";

export const VelevPage: FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Velev maxNrOfSlides={0} />
      <div className="bg-accent text-accent-content">
        <h1> </h1>
        <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0 py-6 lg:py-12">
          <h3 className="text-2xl font-semibold first-letter pt-12 pb-6 sm:text-3xl">
            ABSTRACT
          </h3>
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-10 pb-6">
            <div>
              <p className="pb-3">
                The city aims to enhance daily commutes by providing an
                efficient and healthier alternative to public and private
                transport. This involves developing a charging and locking
                network for light electric vehicles like e-bikes,
                e-kick-scooters, e-skateboards, and e-wheelchairs.
              </p>
            </div>
            <div>
              <p className="pb-3">
                VeleV is an{" "}
                <span className="font-bold">
                  city-wide outdoor charging system for light electric vehicles,
                </span>{" "}
                offering a network of charging locations.
              </p>
              <p className="pb-3">
                It uses <span className="font-bold">the RoPD plug </span> for
                data communication and power transmission, allowing for easy
                locking and charging. The system also features an anti-theft
                function and self-locating function for added security.
              </p>
            </div>
          </div>
        </div>
        <div className="carousel-item contain-fill w-full flex-col-reverse">
          <img src={velev15} alt="VeleV_Final_Project" />
        </div>
      </div>
      <div className="bg-primary text-primary-content">
        <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0">
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-10 pt-6 pb-6">
            <div>
              <h3 className="text-2xl font-semibold first-letter pt-12 pb-6 sm:text-3xl">
                PROJECT BACKGROUND
              </h3>
              <p className="pb-3">
                Alexela Group aims to align future concepts with energy trends
                and changes in city behavior to add value to the company.
              </p>
              <p className="pb-3">
                They study energy distribution systems, global forces, Alexela's
                core areas, values, and vision. They conduct energy research in
                public locations, including city, rural, gas stations, and
                transportation routes, focusing on sustainability and
                electrification values from data analysis.
              </p>
            </div>
            <div>
              <h3 className="text-2xl font-semibold first-letter pt-12 pb-6 sm:text-3xl">
                CREDITS
              </h3>
              <p className="pb-3">
                This was my first project and I contributed less than because of
                my lack of skills. However, I helped with the research part and
                paper prototype.
              </p>
              <p className="pb-3 font-bold">Learning opportunities</p>
              <p className="pb-3">
                Team work, Presenting, Research, Prototyping, Visual design,
                Video making.
              </p>
            </div>
          </div>
        </div>
        <div className="carousel w-full h-96 flex items-end">
          <img src={velev1} alt="traffic" />
        </div>
        <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0">
          <h3 className="text-2xl font-semibold first-letter pt-18 pb-6 sm:text-3xl">
            RESEARCH OBJECTIVES
          </h3>
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-10 pb-6">
            <div>
              <p className="pb-3">
                The study aimed to identify key trends in the energy industry
                and user perspectives. Observations were conducted in public
                spaces, petrol stations, and near transportation routes. The
                study found that people tend to wait in traffic and hurry to
                reach their desired locations, highlighting daily energy-related
                issues. The data analysis revealed that people are often rushing
                to reach their destinations.
              </p>
            </div>
            <div>
              <p className="pb-3">
                The European Commission is implementing higher regulations for
                vehicles to reduce CO2 emissions, raising the question of
                efficient time and energy use while maintaining environmental
                sustainability. Research indicates a growing demand for light
                electric vehicles, necessitating infrastructure improvements to
                provide user-friendly solutions.
              </p>
            </div>
          </div>
          <div className=" container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0 pb-12">
            <div className="w-full">
              <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-6 mt-6 mb-6">
                <div className="w-full mt-6 sm:mb-0">
                  <div className="relative h-full ml-0 mr-0 sm:mr-10 ">
                    <span className="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-accent rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px]"></span>
                    <div className="relative h-full p-5 bg-primary border-2 rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px] border-accent">
                      <p className="mb-2 text-primary-content text-center">
                        A large majority of European citizens live in an urban
                        environment, with over 60 % living in urban areas of
                        over 10,000 inhabitants. They live their daily lives in
                        the same space, and for their mobility share the same
                        infrastructure. Urban mobility accounts for 40 % of all
                        CO2 emissions of road transport and up to 70 % of other
                        pollutants from transport.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="w-full mt-6 sm:mb-0">
                  <div className="relative h-full ml-0 mr-0 sm:mr-10 ">
                    <span className="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-accent rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px]"></span>
                    <div className="relative h-full p-5 bg-primary border-2 rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px] border-accent">
                      <p className="mb-2 text-primary-content text-center">
                        Cities are home to over 70 % of the EU population and
                        account for some 85 % of the Union's GDP. Most journeys
                        begin and end in cities. In many urban areas, however,
                        increasing demand for urban mobility has created a
                        situation that is not sustainable: severe congestion,
                        poor air quality, noise emissions, and high levels of
                        CO2 emissions.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="w-full mt-6 sm:mb-0">
                  <div className="relative h-full ml-0 mr-0 sm:mr-10 ">
                    <span className="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-accent rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px]"></span>
                    <div className="relative h-full p-5 bg-primary border-2 rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px] border-accent">
                      <p className="mb-2 text-primary-content text-center">
                        Looking into transportation-related trends in large
                        countries such as Germany and Norway, it can be seen
                        that people are encouraged by the policies and
                        developing infrastructure and they more often choose
                        light vehicles for short-distance daily commutes,
                        including light electric vehicles like e-bikes, elderly
                        mobility vehicles which can be more accessible for less
                        fit or elderly people.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0">
            <p className="pb-3">
              Our research was focused on finding out human behavior and motion
              in the city, in the context of energy and understanding their
              behavior and habits.
              <span className="font-bold">
                {" "}
                The research methods included literature research about trends
                and megatrends, observations, interviews with light vehicle
                users, and literature research about future concepts.{" "}
              </span>
              For this project, the observations have been made in a variety of
              locations such as parks, petrol stations, and busy streets, mostly
              in a city environment, but also in some rural areas to expand the
              scope.
            </p>
          </div>
        </div>
        <div className="container relative justify-between h-full max-w-6xl px-16 mx-auto xl:px-0">
          <h3 className="text-2xl font-semibold first-letter pt-12 pb-6 sm:text-3xl">
            Observations findings
          </h3>
          <ul className="list-disc pb-12">
            <li>Rushing in traffic by public or private transportation</li>
            <li>
              Pedestrians and drivers are using gadgets while waiting
            </li>{" "}
            <li>Petrol stations are mostly used during peak hours </li>
            <li>People try to use their time as efficiently as they can</li>
            <li>
              Few electric vehicle (electric car) renting places and charging
              places
            </li>
            <li>
              There are several bicycle renting places and locking areas these
              are mostly empty, which means the demand for renting light
              vehicles is substantial{" "}
            </li>
            <li>
              High need for people to go from destination A to destination B 
              Last mile issue.
            </li>
          </ul>
        </div>
      </div>
      <div className="text-primary bg-primary-content">
        <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0">
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-10">
            <div className="py-12">
              Nissan Europe's Chairman, Paul Willcox, has proposed that the
              future fuel station could be the car itself, highlighting the
              potential of technology to address urban challenges. The company
              believes that the future of transportation relies on
              infrastructure and the environment, and its vision includes
              vehicle-to-grid, battery storage, wireless charging, autonomous
              drive technology, and over-the-air connectivity, which could
              revolutionize energy use and distribution in Europe's major
              cities.
            </div>
            <div>
              <div className="w-full h-full relative py-6 lg:py-12">
                <img
                  className="w-full h-full object-contain"
                  src={velev2}
                  alt="Nissan_Future_Station"
                  h-auto
                  w-auto
                  justify-content
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-primary text-primary-content">
        <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0">
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-10">
            <div className="pb-3 pt-12">
              As we noticed earlier the last mile problem was something where we
              saw the opportunity to research more and understand if there could
              be any opportunities worth solving.{" "}
              <span className="font-bold">
                {" "}
                The last mile term is the final step in transportation such as
                walking to the final destination.
              </span>
            </div>
            <div>
              <div className="w-full h-full relative py-12">
                <img
                  className="w-full h-full object-contain"
                  src={velev3}
                  alt="Last_Mile_Issue"
                  h-auto
                  w-auto
                  justify-content
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0">
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-10">
            <div className="w-full h-full relative py-6 lg:py-12">
              <img
                className="w-full h-full object-contain"
                src={velev4}
                alt="LasFamily_route"
                h-auto
                w-auto
                justify-content
              />
            </div>
            <div className="pt-3 pb-12">
              We decided to analyze how a typical family with 3 children goes
              about their daily commutes. It is possible to see that the family
              lives in Mustamäe and works in the city center and the children go
              to different schools and kindergarten. The daily routes can be
              more than 15 km per day. The family uses public and private
              transportation and light vehicles. In terms of public
              transportation, the bus stop locations are permanent and there is
              a big distance still which they need to walk to get to the final
              destination (sometimes more than 15 minutes from the parking lot
              or public bus stop). Sometimes the family members would prefer to
              use a faster way of getting to their destination. Or if it is a
              shorter distance they could use the light vehicles, but there
              would be questions related to parking and charging them.
            </div>{" "}
          </div>
        </div>
      </div>
      <div className="bg-primary-content test-primary text-center my-24 mx-24 lg:my-48 lg:mx-60">
        <h2 className="pt-8 text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl font-semibold lg:py-12">
          HOW ALL ELECTRICITY-CONSUMING PRODUCTS OR VEHICLES COULD BE CHARGED IN
          OUTDOOR CONDITIONS?
        </h2>
      </div>
      <div className="bg-primary text-primary-content">
        <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0">
          <h3 className="text-2xl font-semibold first-letter pt-18 pb-6 sm:text-3xl">
            CONCEPT I
          </h3>
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-10 pb-6">
            <div>
              <p className="pb-3">
                The concept is to create an electric kick-scooter for children
                and adults, offering flexibility in transportation and time
                management. The electric scooter can charge various gadgets via
                USB wire and can be shared with other light vehicles like
                trolleys, skateboards, roller skates, bicycles, and segways.
                Safety features include a smart helmet with augmented reality,
                GPS, calls, and overall information, making the scooter more
                immersive. The personal scooter is a solution for last-mile
                issues as it can be carried and used constantly.
              </p>
            </div>
            <div className="w-full h-full relative py-6 lg:py-12">
              <img
                className="w-full h-full object-contain"
                src={velev5}
                alt="Concept_one_network"
                h-auto
                w-auto
                justify-content
              />
            </div>
          </div>
        </div>

        <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0">
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-24 lg:grid">
            <div className="w-full h-full relative py-6 lg:py-12">
              <img
                className="w-full h-full object-contain"
                src={velev6}
                alt="Concept_two_Alexela_Tree"
                h-auto
                w-auto
                justify-content
              />
            </div>

            <div className="text-box">
              <h3 className="text-2xl font-semibold first-letter pt-12 pb-6 sm:text-3xl">
                CONCEPT II
              </h3>
              <p className="pb-3">
                The Alexela network aims to create a comfortable space for
                people to enjoy light transportation vehicles and gadgets while
                working, studying, or relaxing. The network could potentially
                use renewable energy sources like solar panels to charge devices
                and vehicles. The main value of the network is marketing, as
                Alexela is developing an electric light vehicle commute. Other
                business add-ons could include building and renting light
                vehicles, and implementing additional electricity charging
                services like delivery drones and robots. This could create an
                ecosystem for charging electrical devices throughout the city,
                addressing the last mile problem.
              </p>
            </div>
          </div>
        </div>
        <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0">
          <h3 className="text-2xl font-semibold first-letter pt-12 pb-6 sm:text-3xl">
            CONCEPT III
          </h3>
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-10 pb-6">
            <div>
              <p className="pb-3">
                Renting/charging/locking (RCL) stations are a service-product
                concept that aims to create modules for locking and charging
                light electric vehicles (LEVs). These modules can be combined or
                separated depending on the need. One module would focus on light
                vehicle locking and charging, ideal near schools or public
                spaces. Renting a module allows for a network with different
                groups, such as tourist, entertainment, and last-mile services.
                Research has found the RoPD® – Rosenberger Power Data Connector
                plug, which is designed for data communication and power
                transmission in the Light Electric Vehicle Industry (LEV).
                However, it lacks a security lock and is not suitable for the
                Alexela tree network.
              </p>
            </div>
            <div className="w-full h-full relative py-6 lg:py-12">
              <img
                className="w-full h-full object-contain"
                src={velev7}
                alt="Concept_three_network"
                h-auto
                w-auto
                justify-content
              />
            </div>
          </div>
        </div>

        <div className="box-content bg-accent text-accent-content ">
          <div className="text-center mx-4 pb-3 md:py-5 sm:mx-10 md:mx-32">
            <h3 className="text-2xl font-semibold first-letter pt-12 pb-6 sm:text-3xl">
              THE BIG IDEA
            </h3>
            <h4 className="font-semibold pb-12 sm:text-2xl ">
              All-in-one functional charging and locking system for Light
              Electric Vehicles.
            </h4>
          </div>
        </div>
      </div>
      <div className="bg-primary text-primary-content">
        <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0">
          <h3 className="text-2xl font-semibold first-letter pt-18 pb-6 sm:text-3xl">
            FIRST PROTO
          </h3>
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-10 pb-6">
            <div>
              <p className="pb-3">
                The initial visualization of all-in-one charging featured a half
                stair with three steps, each mounted with different LEV sizes.
                This allowed people to sit on the stairs, drink coffee, or
                charge their phones while scooters or skateboards charged
                inside. However, the idea was not universally applicable and was
                targeted at a specific group of young people.
              </p>
            </div>
            <div className="w-full h-full relative py-6 lg:py-12">
              <img
                className="w-full h-full object-contain"
                src={velev8}
                alt="First_prototype"
                h-auto
                w-auto
                justify-content
              />
            </div>
          </div>
        </div>
        <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0">
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-24 lg:grid">
            <div>
              <div className="w-full h-full relative py-6 lg:py-12">
                <img
                  className="w-full h-full object-contain"
                  src={velev9}
                  alt="Design_Ideas"
                  h-auto
                  w-auto
                  justify-content
                />
              </div>
            </div>
            <div>
              <h3 className="text-2xl font-semibold first-letter pt-12 pb-6 sm:text-3xl">
                EXPECTED OUTCOMES OF THE DESIGN
              </h3>
              <p className="pb-3">
                The second visualization aimed to design a sheet metal structure
                for big-wheeled LEVs, including e-bikes, kick and push bikes,
                and segways, featuring wireless charging and easy locking. The
                Alexela blue system had a marketing presence in the city, but
                not for all LEV types.
              </p>
            </div>
          </div>
          <h3 className="text-2xl font-semibold first-letter pt-12 pb-6 sm:text-3xl">
            NEW TRY
          </h3>
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-10 pb-6">
            <div>
              <p className="pb-3">
                After we attempted to create a system for as many types of LEVs
                as possible we tried to rethink the situation. We focused only
                on e-bikes because their sales were increasing across Europe the
                most in terms of LEVs.
              </p>
            </div>
            <div className="w-full h-full relative py-6 lg:py-12">
              <img
                className="w-full h-full object-contain"
                src={velev10}
                alt="New_Try"
                h-auto
                w-auto
                justify-content
              />
            </div>
          </div>
        </div>
        <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0">
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-24 lg:grid">
            <div className="w-full h-full relative py-6 lg:py-12">
              <img
                className="w-full h-full object-contain"
                src={velev11}
                alt="New_Try"
                h-auto
                w-auto
                justify-content
              />
            </div>
            <div>
              <h3 className="text-2xl font-semibold first-letter pt-12 pb-6 sm:text-3xl">
                FINAL FINAL VERSION
              </h3>
              <p className="pb-3">
                The system required a new, updated design, leading to an
                advanced locking system. However, the design was not modern or
                innovative, and the locking mechanism was illogical, requiring
                users to wrap a wire around their vehicle.
              </p>
            </div>
          </div>
        </div>
        <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0">
          <h3 className="text-2xl font-semibold first-letter pt-12 pb-6 sm:text-3xl">
            FINAL FINAL FINAL VERSION
          </h3>
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-10 pb-6">
            <div>
              <p className="pb-3">
                The module's form was refined to communicate softer language and
                be innovative, ultimately resulting in a simple arc design for
                easy access and charging.
              </p>
            </div>
            <div className="w-full h-full relative py-6 lg:py-12">
              <img
                className="w-full h-full object-contain"
                src={velev13}
                alt="Final_version"
                h-auto
                w-auto
                justify-content
              />
            </div>
          </div>
        </div>
        <div className="w-full h-full relative">
          <img
            className="w-full h-full object-contain"
            src={velev16}
            alt="Final_version"
            h-auto
            w-auto
            justify-content
          />
        </div>
        <div className="w-full h-full relative">
          <img
            className="w-full h-full object-contain"
            src={velev14}
            alt="Final_version"
            h-auto
            w-auto
            justify-content
          />
          <div className="w-full h-full relative">
            <img
              className="w-full h-full object-contain"
              src={velev15}
              alt="Final_version"
              h-auto
              w-auto
              justify-content
            />
          </div>
        </div>
        <div className="relative flex items-center justify-center overflow-hidden">
          <video className=" w-full rounded-lg" controls>
            <source src="/video/VeleV.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="box-content bg-secondary text-secondary-content ">
          <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0">
            <h3 className="text-2xl font-semibold first-letter pt-18 pb-6 sm:text-3xl">
              CONCLUSION
            </h3>
            <p className="pb-3">
              Our goal was to design an all-in-one charging station for emerging
              electric light vehicles, utilizing existing technologies and the
              RoPD® plug for data communication and magnetic locking.
            </p>
            <p className="pb-3">
              The VeleV concept, developed using RoPD wire specifically for
              LEVs, is a promising development in the growing number of
              well-designed LEVs.
            </p>
            <p className="pb-3">
              VeleV is a concept that is worth developing further for not only a
              charging and locking place for LEVs but it can also be developed
              into a service provider for renting the LEVs and for marketing
              purposes.
            </p>
            <p className="pt-6 font-bold pb-12">
              Grete Pärtel(Taimre), Martti Rosenblatt, Alise Lezdina
            </p>
          </div>
        </div>
      </div>
      <div className="py-12 text-center">
        <button className="btn btn-outline btn-secondary justify-center rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px]">
          <Link to={"../Merbest"}>Next Case Study</Link>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-5 h-5"
          >
            <path
              fillRule="evenodd"
              d="M3 10a.75.75 0 0 1 .75-.75h10.638L10.23 5.29a.75.75 0 1 1 1.04-1.08l5.5 5.25a.75.75 0 0 1 0 1.08l-5.5 5.25a.75.75 0 1 1-1.04-1.08l4.158-3.96H3.75A.75.75 0 0 1 3 10Z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>{" "}
      <ScrollToTop smooth component={<p style={{ color: "#7AA874" }}>UP</p>} />
    </>
  );
};

import { FC } from "react";
import { Introduction } from "./Introduction";
import { AboutMe } from "./AboutMe";
import { CaseStudies } from "./CaseStudies";
import ScrollToTop from "react-scroll-to-top";

export const Landing: FC = () => {
  return (
    <>
      <h1> </h1>
      <Introduction />
      <AboutMe />
      <CaseStudies />
      <ScrollToTop smooth component={<p style={{ color: "#7AA874" }}>UP</p>} />
    </>
  );
};

import { FC } from "react";
import profile from "../assets/avataaars.svg";

export const AboutGrete: FC = () => {
  return (
    <>
      <div className="text-primary-content bg-gradient-to-b from-secondary from-10% via-accent via-30% bg-pink-300 to-60% to-transparent  ">
        <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0 pb-12">
          <h1 className="text-4xl font-semibold pt-12 pb-6 sm:text-5xl md:text-6xl">
            About me
          </h1>
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-24">
            <div>
              <p className="pb-3">
                Always wanted to create something useful and helpful for the
                people.
              </p>
              <p className="py-3">
                So here I am, applying for my dream job. Shorty about myself...I
                am a mother of 2, wife and a dog owner.
              </p>
              <p className="py-3">
                I like to create innovative solutions and get new knowledge
                about everything..
              </p>{" "}
              <h1 className="text-3xl text-left font-semibold py-3 sm:text-4xl sm:py-4 md:text-5xl">
                I am a Design enthusiast and innovative searcher{" "}
              </h1>
              <p className="py-3">
                I am passionate about innovation, UI/UX, and photography
              </p>{" "}
              <p className="pt-3 pb-12">
                During the TalTech master program in Design and Technology, I
                created three projects, participated in hackathons, and Baltic
                senior programs, and started my own company, HPack, which failed
                due to a lack of belief.
              </p>
            </div>
            <div>
              <div className="w-full h-full relative my-10">
                <img
                  className="w-full object-contain mask mask-squircle"
                  src={profile}
                  alt="greteprofile"
                  h-auto
                  w-auto
                  justify-content
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0">
          <div className="w-full">
            <div className="flex flex-col w-full mt-6 mb-10 sm:flex-row">
              <div className="w-full sm:w-1/2 ">
                <div className="relative h-full ml-0 mb-6 sm:mr-6">
                  <span className="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-secondary rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px]"></span>
                  <div className="relative h-full p-5 bg-primary border-2 rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px] border-secondary">
                    <div className="flex items-center -mt-1 ">
                      <h3 className="my-2 ml-3 text-lg font-bold text-primary-content">
                        Design tools
                      </h3>
                    </div>

                    <p className="mb-2 text-primary-content">
                      Good knowledge of creating visually appealing interfaces
                      using tools like Adobe XD and Figma
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full sm:w-1/2">
                <div className="relative h-full ml-0 mb-6 sm:mr-6">
                  <span className="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-secondary rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px]"></span>
                  <div className="relative h-full p-5 bg-primary border-2 rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px] border-secondary">
                    <div className="flex items-center -mt-1">
                      <h3 className="my-2 ml-3 text-lg font-bold text-primary-content">
                        Research Methods
                      </h3>
                    </div>

                    <p className="mb-2 text-primary-content">
                      Design Thinking, User interviews, Surveys, Journey mapping
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full sm:w-1/2">
                <div className="relative h-full ml-0 mb-6 sm:mr-6">
                  <span className="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-secondary rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px]"></span>
                  <div className="relative h-full p-5 bg-primary border-2 rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px] border-secondary">
                    <div className="flex items-center -mt-1">
                      <h3 className="my-2 ml-3 text-lg font-bold text-primary-content">
                        Service Design Techniques
                      </h3>
                    </div>

                    <p className="mb-2 text-primary-content">
                      Service blueprinting, Prototyping, Testing
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

import { FC } from "react";

export const Work: FC = () => {
  return (
    <>
      {" "}
      <div className="bg-secondary text-secondary-content">
        <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0 pb-12">
          <h3 className="text-2xl font-semibold pt-12 pb-12 sm:text-3xl ">
            WORK
          </h3>
          <ul className="mx-8 timeline timeline-snap-icon max-md:timeline-compact timeline-vertical">
            <li>
              <TimelineMiddle />
              <div className="timeline-start md:text-end mb-10">
                <time className="text-4xl md:text-5xl">2019-2023</time>
                <div className="text-2xl">Lidl Eesti OÜ</div>
                <div className="text-lg font-bold">Food Promotion</div>
                <div className="text-left ">
                  <br />* Support with market analysis, products, prices,
                  special offers, packages, development trends, and loyalty
                  programs.
                  <br />* Advertising planning and communication to the
                  departments concerned.
                  <br />* Assist with research to identify the best products and
                  suppliers.
                  <br />* Participate in product strategy development.
                </div>
              </div>
              <hr />
            </li>
            <li>
              <hr />
              <TimelineMiddle />
              <div className="timeline-end md:mb-10">
                <time className="text-4xl md:text-5xl">2018-2019</time>
                <div className="text-2xl">Dada Gopal OÜ</div>
                <div className="text-lg font-bold">
                  Photographer and Graphic designer{" "}
                </div>
                <br />* Create and design various materials for digital and
                print. <br />* Manage graphic design projects for product
                catalogs, brochures, and sales flyers via print and online.
                <br />* Design daily newspaper ads, create brochures, logos,
                business cards, newsletters, prepare photos, and outline images.
              </div>
              <hr />
            </li>
            <li>
              <hr />
              <TimelineMiddle />
              <div className="timeline-start md:text-end mb-10">
                <time className="text-4xl md:text-5xl">2016-2018</time>
                <div className="text-2xl">Sangar Shirt Factory</div>
                <div className="text-lg font-bold">Production assistant</div>
                <div className="text-left ">
                  <br />* Checking and adding information on official production
                  orders.
                  <br />* Confirming received components against the PO and
                  delivery notes.
                  <br />* Marking and logging fabric deliveries Any other duties
                  as required by your line Manager.
                </div>
              </div>
              <hr />
            </li>
            <li>
              <hr />
              <TimelineMiddle />
              <div className="timeline-end">
                <time className="text-4xl md:text-5xl">2015-2015</time>
                <div className="text-2xl">adidas Group</div>
                <div className="text-lg font-bold">
                  Production development assistant
                </div>
                <br />
                * Understand and execute the product creation process according
                to calendar timelines.
                <br />* Work within a cross-functional team to enable a smooth
                product creation process with an optimum product outcome. *
                Analyse range performance.
                <br />* Build knowledge to be able to maintain product
                information systems according to process timelines.
                <br />* Experience market, consumer, and retailer need to be
                able to create industry-leading products.
              </div>
              <hr />
            </li>
            <li>
              <hr />
              <div className="timeline-middle">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="h-5 w-5"
                >
                  <path />
                </svg>
              </div>
            </li>
          </ul>
          <p className="pb-12"></p>
        </div>
      </div>
    </>
  );
};

const TimelineMiddle = () => (
  <div className="timeline-middle">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className="h-5 w-5"
    >
      <path
        fillRule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
        clipRule="evenodd"
      />
    </svg>
  </div>
);

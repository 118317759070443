import React, { FC } from "react";

import grete from "../assets/avataaars.svg";
import { Link } from "react-router-dom";

import GreteTaimre from "../PDFViewer";

export const Contactme: FC = () => {
  return (
    <div className="bg-primary bg-gradient-to-b from-secondary from-75%  to-transparent">
      <h1> </h1>
      <div className=" container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0 pb-12">
        <div className="w-full">
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-6 mt-6 mb-6">
            <div className="w-full mt-6 sm:mb-0">
              <div className="relative h-full ml-0 mr-0 sm:mr-10 ">
                <span className="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-accent rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px]"></span>
                <div className="relative h-full p-5 bg-primary border-2 rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px] border-accent">
                  <div className="flex items-center -mt-1">
                    <img src={grete} alt="grete" className="w-12 h-12" />
                    <h3 className="my-2 ml-3 text-lg font-bold text-primary-content">
                      Hei, I'm Grete Taimre
                    </h3>
                  </div>
                  <p className="mb-2 text-primary-content">
                    I am junior UI/UX Designer. Eager to learn and grow in the
                    field of UI/UX Design.
                  </p>
                  <p className="mt-3 mb-1 text-xs font-medium text-accent uppercase">
                    ------------
                  </p>
                  <div className="flex justify-center space-x-4">
                    <a
                      href="https://www.facebook.com/gretepartel/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://img.icons8.com/fluent/30/000000/facebook-new.png"
                        alt=""
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/in/gretetaimre/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://img.icons8.com/fluent/30/000000/linkedin-2.png"
                        alt=""
                      />
                    </a>

                    <a
                      href=" https://m.me/gretepa"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://img.icons8.com/fluent/30/000000/facebook-messenger--v2.png"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full mt-6 sm:mb-0">
              <div className="relative h-full ml-0 mr-0 sm:mr-10 ">
                <span className="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-accent rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px]"></span>
                <div className="relative h-full p-5 bg-primary border-2 rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px] border-accent">
                  <div className="flex items-center -mt-1">
                    <h3 className="my-2 ml-3 text-lg font-bold text-primary-content">
                      Look into my Resume
                    </h3>
                  </div>

                  <p className="mb-2 text-primary-content">
                    You can download my resume here.
                    <p className="mt-3 mb-1 text-xs font-medium text-secondary uppercase">
                      ------------
                    </p>
                    <GreteTaimre />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full mt-6 sm:mb-0">
            <div className="relative h-full ml-0 mr-0 sm:mr-10 ">
              <span className="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-accent rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px]"></span>
              <div className="relative h-full p-5 bg-primary border-2 rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px] border-accent">
                <div className="flex items-center -mt-1">
                  <h3 className="my-2 ml-3 text-lg font-bold text-primary-content">
                    Passionate about
                  </h3>
                </div>

                <p className="mb-2 text-primary-content">
                  Design Thinking, Research, Prototyping, Innovation, and
                  Technology. Also, Visuals and Photography.
                  <p className="mt-3 mb-1 text-xs font-medium text-secondary uppercase">
                    ------------
                  </p>
                  <button className="btn btn-outline btn-secondary rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px]">
                    <Link to={"../about"}>Read more about me</Link>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        fillRule="evenodd"
                        d="M3 10a.75.75 0 0 1 .75-.75h10.638L10.23 5.29a.75.75 0 1 1 1.04-1.08l5.5 5.25a.75.75 0 0 1 0 1.08l-5.5 5.25a.75.75 0 1 1-1.04-1.08l4.158-3.96H3.75A.75.75 0 0 1 3 10Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

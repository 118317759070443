import { FC, useEffect } from "react";
import { Merbest } from "./Merbest";
import circa1 from "../assets/circa.jpg";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import merbest1 from "../assets/Screenshot 2024-04-17 095233.png";
import merbest2 from "../assets/WebMerbest.png";
import merbest3 from "../assets/MobileMerbest.png";
import { link } from "fs";

export const MerbestPage: FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Merbest maxNrOfSlides={0} />
      <div className="bg-primary text-primary-content whitespace-normal">
        <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0 pb-12">
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-10 pt-6 pb-6">
            <div>
              <h3 className="text-2xl font-semibold first-letter pt-12 pb-6 sm:text-3xl">
                PROJECT BACKGROUND
              </h3>
              <p className="pb-3">
                The website is a platform for people who want to buy roofing
                materials such as fiberglass cement sheets and extra
                accessories.
              </p>
              <Link to="https://merbest.ee/">
                <span className="font-bold">webpage</span>
              </Link>
            </div>
            <div>
              <h3 className="text-2xl font-semibold first-letter pt-12 pb-6 sm:text-3xl">
                CREDITS
              </h3>
              <p className="pb-3">
                During my school project, I created a new UI design for the
                Merbest website. While analyzing the information and making it
                more user-friendly, I also created a new logo for the company.
                More information about the project can be found{" "}
                <Link to="https://www.figma.com/community/file/1376197069863715636/merbest-webpage-update">
                  <span className="font-bold">Here</span>
                </Link>
                .
              </p>
              <p className="pt-3 lg:pt-6 pb-3 font-bold">
                {" "}
                Learning opportunities
              </p>
              <p className="pb-3">Figma, UI/UX design</p>
            </div>
          </div>
          <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0 pb-12">
            <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-10 pt-6 pb-6">
              <div>
                <div className="w-full h-full relative">
                  <img
                    className="w-full h-full object-contain"
                    src={merbest1}
                    alt="merbestWebPage"
                    h-auto
                    w-auto
                    justify-content
                  />
                </div>
              </div>
              <div>
                <h3 className="text-2xl font-semibold first-letter pt-18 pb-6 sm:text-3xl">
                  PROBLEMS
                </h3>
                <p className="pb-3 font-bold">Poor User Experience:</p>
                <p className="pb-3">
                  Slow loading times, confusing layouts, and non-intuitive
                  interfaces drive users away.
                </p>
                <p className="pb-3 font-bold">Outdated Visuals:</p>
                <p className="pb-3">
                  Old-fashioned graphics and design elements make a site look
                  unprofessional.
                </p>
                <p className="pb-3 font-bold">Design:</p>
                <p className="pb-3">
                  Ineffective design can hinder usability and detract from the
                  site's overall appeal
                </p>
                <p className="pb-3 font-bold">Navigation:</p>
                <p className="pb-3">
                  Ambiguous menus and broken links frustrate users, making
                  information hard to find.
                </p>
                <p className="pb-3 font-bold">Social Media Integration:</p>
                <p className="pb-3">
                  Lack of share buttons and inactive links limit audience
                  engagement and reach.
                </p>
                <p className="pb-3 font-bold">Cluttered Homepage:</p>
                <p className="pb-3">
                  Overwhelming information and excessive ads obscure the site's
                  main message.
                </p>
                <p className="pb-3 font-bold">Mobile Incompatibility:</p>
                <p className="pb-3">
                  Non-responsive design and poor mobile interfaces alienate
                  users on smartphones and tablets.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="box-content bg-secondary text-secondary-content ">
        <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0 pb-12">
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-10 pt-6 pb-6">
            <div>
              <h3 className="text-2xl font-semibold first-letter pt-18 pb-6 sm:text-3xl">
                SOLUTIONS
              </h3>
              <p className="pb-3 font-bold">User Experience:</p>
              <p className="pb-3">
                By improving the site's speed, layout, and interface, we can
                increase user engagement and retention.
              </p>
              <p className="pb-3 font-bold">Visuals:</p>
              <p className="pb-3">
                Modern graphics and design elements will enhance the site's
                professionalism and appeal.
              </p>
              <p className="pb-3 font-bold">Design:</p>
              <p className="pb-3">
                An effective design will improve usability and enhance the
                site's overall aesthetic.
              </p>
              <p className="pb-3 font-bold">Navigation:</p>
              <p className="pb-3">
                Clear menus and functional links will help users find
                information quickly and easily.
              </p>
              <p className="pb-3 font-bold">Social Media Integration:</p>
              <p className="pb-3">
                Share buttons and active links will increase audience engagement
                and expand the site's reach.
              </p>
              <p className="pb-3 font-bold">Cluttered Homepage:</p>
              <p className="pb-3">
                Streamlining information and reducing ads will help users focus
                on the site's main message.
              </p>
              <p className="pb-3 font-bold">Mobile Compatibility:</p>
              <p className="pb-3">
                Responsive design and improved mobile interfaces will attract
                and retain users on smartphones and tablets.
              </p>
            </div>
            <div>
              <h3 className="text-2xl font-semibold first-letter pt-18 pb-6 sm:text-3xl">
                MOBILE VERSION
              </h3>
              <div className="w-full h-full relative">
                <img
                  className="w-full object-contain"
                  src={merbest3}
                  alt="webpage"
                  h-auto
                  w-auto
                  justify-content
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-full relative">
          <img
            className="w-full h-full object-contain"
            src={merbest2}
            alt="webpage"
            h-auto
            w-auto
            justify-content
          />
        </div>
      </div>
      <div className="bg-primary text-primary-content whitespace-normal">
        <div className="py-12 text-center">
          <button className="btn btn-outline btn-secondary justify-center rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px]">
            <Link to="https://www.figma.com/community/file/1376197069863715636/merbest-webpage-update">
              Figma File
            </Link>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-5 h-5"
            >
              <path
                fillRule="evenodd"
                d="M3 10a.75.75 0 0 1 .75-.75h10.638L10.23 5.29a.75.75 0 1 1 1.04-1.08l5.5 5.25a.75.75 0 0 1 0 1.08l-5.5 5.25a.75.75 0 1 1-1.04-1.08l4.158-3.96H3.75A.75.75 0 0 1 3 10Z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="py-12 text-center">
        <button className="btn btn-outline btn-secondary justify-center rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px]">
          <Link to={"../Aigor"}>Next Case Study</Link>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-5 h-5"
          >
            <path
              fillRule="evenodd"
              d="M3 10a.75.75 0 0 1 .75-.75h10.638L10.23 5.29a.75.75 0 1 1 1.04-1.08l5.5 5.25a.75.75 0 0 1 0 1.08l-5.5 5.25a.75.75 0 1 1-1.04-1.08l4.158-3.96H3.75A.75.75 0 0 1 3 10Z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>{" "}
      <ScrollToTop smooth component={<p style={{ color: "#7AA874" }}>UP</p>} />
    </>
  );
};

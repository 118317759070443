import React from "react";

const GreteTaimre = () => {
  const onButtonClick = () => {
    const pdfUrl = "./Grete_Taimre_CV.pdf";
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "GreteTaimre_CV.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>
      <button
        className="btn btn-outline btn-secondary rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px]"
        onClick={onButtonClick}
      >
        Download PDF
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="w-5 h-5"
        >
          <path
            fillRule="evenodd"
            d="M3 10a.75.75 0 0 1 .75-.75h10.638L10.23 5.29a.75.75 0 1 1 1.04-1.08l5.5 5.25a.75.75 0 0 1 0 1.08l-5.5 5.25a.75.75 0 1 1-1.04-1.08l4.158-3.96H3.75A.75.75 0 0 1 3 10Z"
            clipRule="evenodd"
          />
        </svg>
      </button>
    </>
  );
};

export default GreteTaimre;

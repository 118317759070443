import { FC, useEffect } from "react";
import { AboutGrete } from "./AboutGrete";
import { Awards } from "./Awards";
import { Education } from "./Education";
import { Work } from "./Work";
import ScrollToTop from "react-scroll-to-top";

export const AboutPage: FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <AboutGrete />
      <Awards />
      <Education />
      <Work />
      <ScrollToTop smooth component={<p style={{ color: "#7AA874" }}>UP</p>} />
    </>
  );
};

import { FC } from "react";
import gamo from "../assets/Gamo.svg";

export const Awards: FC = () => {
  return (
    <>
      <div className="bg-accent text-accent-content ">
        <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0 pb-12">
          <h3 className="text-2xl font-semibold pt-12 pb-6 sm:text-3xl ">
            AWARDS
          </h3>

          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-6">
            <div className="w-full h-full relative my-10">
              <img
                className="w-full object-contain mask mask-squircle"
                src={gamo}
                alt="Gamo"
                h-auto
                w-auto
                justify-content
              />
            </div>
            <div className="content-center">
              <div className="pb-3">
                <span className="font-bold">The Innovation Challenge </span>
                took place at TalTech Mektory on 20th October 2018. I was
                teaming up with my friend Annett Saarik and took part in the
                Challenge.{" "}
                <span className="font-bold">
                  We created GaMo, which won the first place and €2.500.
                </span>
                <p className="font-bold pt-3 ">
                  "GaMo is a place, where players will get feedback on how
                  professional players will solve the same situation. Also,
                  professional teams can recruit new team members there."
                </p>
                <p className="pb-12 lg:pb-18"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

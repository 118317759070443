import { FC } from "react";
import greteLandingLogo from "./assets/Logo.svg";
import { Link } from "react-router-dom";

interface NavigationBarProps {}
export const NavigationBar: FC<NavigationBarProps> = () => {
  return (
    <div className="navbar bg-primary text-primary-content z-[1] h-3">
      <div className="navbar-start ">
        <Link to={"/"}>
          <img src={greteLandingLogo} alt="grete landing logo" />
        </Link>
      </div>
      <div className="navbar-end">
        <div className="dropdown">
          <div tabIndex={0} role="button" className="btn btn-neutral lg:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="fill"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </div>
          <ul
            tabIndex={0}
            className="menu dropdown-content mt-3 z-[1] bg-primary text-primary-content rounded w-52 position-fixed top-14 right-0"
          >
            <li>
              <div className="hover:underline">
                <Link to={"about"}>About</Link>
              </div>
            </li>
            <details className="dropdown">
              <summary className="  bg-primary hover:underline">
                Case Studies
              </summary>
              <ul className=" menu dropdown-content bg-primary mt--7 text-primary-content z-[1]">
                <ul>
                  <li>
                    <div className="hover:underline">
                      <Link to={"Aigor"}>Aigor</Link>
                    </div>
                  </li>
                  <li>
                    <div className="hover:underline">
                      <Link to={"CircaDia"}>Circa Dia</Link>
                    </div>
                  </li>
                  <li>
                    <div className="hover:underline">
                      <Link to={"Velev"}>Velev</Link>
                    </div>
                  </li>
                  <li>
                    <div className="hover:underline">
                      <Link to={"Merbest"}>Merbest</Link>
                    </div>
                  </li>
                </ul>
              </ul>
            </details>
            <li>
              <div className="hover:underline">
                <Link to={"Contact"}>Contact</Link>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="navbar-end hidden lg:flex">
        <ul className="menu menu-horizontal  px-1 z-[1] bg-primary text-primary-content">
          <li>
            <div className="hover:underline">
              <Link to={"about"}>About</Link>
            </div>
          </li>
          <li>
            <details>
              <summary>
                <div className="hover:underline"> Case Studies </div>
              </summary>
              <ul className=" bg-primary text-primary-content z-[1] rounded">
                <li>
                  <div className="hover:underline">
                    <Link to={"Aigor"}>Aigor</Link>
                  </div>
                </li>
                <li>
                  <div className="hover:underline">
                    <Link to={"CircaDia"}>Circa Dia</Link>
                  </div>
                </li>
                <li>
                  <div className="hover:underline">
                    <Link to={"Velev"}>Velev</Link>
                  </div>
                </li>
                <li>
                  <div className="hover:underline">
                    <Link to={"Merbest"}>Merbest</Link>
                  </div>
                </li>
              </ul>
            </details>
          </li>
          <li>
            <div className="hover:underline">
              <Link to={"Contact"}>Contact</Link>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

import { FC } from "react";
import { CaseStudyBase } from "../landing/CaseStudyBase";
import velevLogo from "../assets/velev-logo.svg";

interface Props {
  maxNrOfSlides?: number;
}

export const Velev: FC<Props> = ({ maxNrOfSlides }) => {
  return (
    <CaseStudyBase
      heading="VeleV"
      bgImage={velevLogo}
      slideId={1}
      mxNrOfSlides={maxNrOfSlides ?? 3}
      navigation="velev"
    >
      <span className="font-bold">
        all-in-one outdoor charging and locking system for light electric
        vehicles{" "}
      </span>
      <span>
        that adapts to the future changes in the city. The outdoor charging
        solution is offered as a network throughout the city, with many charging
        locations.
      </span>
    </CaseStudyBase>
  );
};

import { FC } from "react";
import { CaseStudyBase } from "../landing/CaseStudyBase";
import merbest from "../assets/Screenshot 2024-04-17 095233.png";

interface Props {
  maxNrOfSlides?: number;
}

export const Merbest: FC<Props> = ({ maxNrOfSlides }) => {
  return (
    <CaseStudyBase
      heading="Merbest"
      bgImage={merbest}
      slideId={4}
      mxNrOfSlides={maxNrOfSlides ?? 4}
      navigation="merbest"
    >
      <span className="font-bold">
        Webpage where you can find the best deals on the market for your roof{" "}
      </span>
    </CaseStudyBase>
  );
};

import { FC } from "react";
import { CaseStudyBase } from "../landing/CaseStudyBase";
import aigor from "../assets/workspace_1.svg";

interface Props {
  maxNrOfSlides?: number;
}

export const Aigor: FC<Props> = ({ maxNrOfSlides }) => {
  return (
    <CaseStudyBase
      heading="Aigor"
      bgImage={aigor}
      slideId={3}
      mxNrOfSlides={maxNrOfSlides ?? 3}
      navigation="aigor"
    >
      <span className="font-bold">
        virtual space interaction platform for employees to encouraging more
        real life interaction{" "}
      </span>
      <span>throughout testing and prototyping in advantance.</span>
    </CaseStudyBase>
  );
};

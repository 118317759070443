import React, { FC } from "react";
import greteLandingLogo from "./assets/Logo.svg";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";

interface FooterProps {}

export const Footer: FC<FooterProps> = () => {
  return (
    <footer className="footer footer-center pt-6 bg-primary text-primary-content">
      <h1> </h1>
      <aside>
        <Link to={"/"}>
          <img src={greteLandingLogo} alt="grete landing logo" />
        </Link>
      </aside>
      <nav>
        <div className="flex justify-center space-x-4">
          <a
            href="https://www.facebook.com/gretepartel/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://img.icons8.com/fluent/30/000000/facebook-new.png"
              alt=""
            />
          </a>
          <a
            href="https://www.linkedin.com/in/gretetaimre/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://img.icons8.com/fluent/30/000000/linkedin-2.png"
              alt=""
            />
          </a>

          <a
            href=" https://m.me/gretepa"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://img.icons8.com/fluent/30/000000/facebook-messenger--v2.png"
              alt=""
            />
          </a>
        </div>
      </nav>
      <div className="flex-grow-0 justify-center font-semibold text-center">
        <p>UI/UX DESIGN AND RESEARCH</p>
        <p>(+372) 566 823 18</p>
        <p>grete.taimre@gmail.com</p>
        <p className="p-3"></p>
      </div>
      <ScrollToTop smooth component={<p style={{ color: "#7AA874" }}>UP</p>} />
    </footer>
  );
};

import React, { FC } from "react";
import { Aigor } from "../case_studies/Aigor";
import { Velev } from "../case_studies/Velev";
import { CircaDia } from "../case_studies/CircaDia";

export const CaseStudies: FC = () => {
  return (
    <>
      <div className="bg-primary bg-gradient-to-t from-secondary from-75%  to-transparent text-center">
        <div className="text-center mx-4 sm:mx-10 md:mx-24 max-w-6xl">
          <h2 className="text-primary-content text-4xl font-semibold pt-20 pb-6 sm:text-5xl md:text-6xl">
            Case Studies
          </h2>{" "}
        </div>
        <div className=" carousel w-full h-96">
          <Velev />
          <CircaDia />
          <Aigor />
        </div>
      </div>
    </>
  );
};

import React, { FC, ReactNode } from "react";
import { Link } from "react-router-dom";

interface CaseStudyBaseProps {
  heading: string;
  bgImage?: string;
  children: ReactNode;
  slideId: number;
  mxNrOfSlides: number;
  navigation: string;
}

export const CaseStudyBase: FC<CaseStudyBaseProps> = ({
  heading,
  bgImage,
  children,
  slideId,
  mxNrOfSlides,
  navigation,
}) => {
  const prevSlide = slideId === 1 ? mxNrOfSlides : slideId - 1;
  const nextSlide = slideId === mxNrOfSlides ? 1 : slideId + 1;
  return (
    <div id={`slide${slideId}`} className="carousel-item relative w-full z-0">
      <div className=" absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-gradient-to-r from-accent via-secondary to-pink-300 opacity-50 "></div>
      <div
        className="hero w-full"
        style={{
          backgroundImage: `url(${bgImage})`,
        }}
      >
        <Link
          to={`/${navigation}`}
          className="hero-content text-center text-primary  "
        >
          <div className="max-w-sm">
            <h2 className="text-3xl align-top lg:text-5xl font-bold text-pretty p-3 sm:p-4 lg:p-6 text-primary">
              {heading}
            </h2>
            <p className="box-content rounded-tl-[30px] rounded-tr-[50px] rounded-br-[30px] rounded-bl-[50px] bg-primary">
              <p className="text-pretty text-center text-primary-content p-3 m-4">
                {children}
              </p>
            </p>
          </div>
        </Link>
        {(mxNrOfSlides && (
          <div className="bottom-4 flex absolute md:justify-between md:transform md:left-5 md:right-5 md:top-1/2">
            <a
              href={`#slide${prevSlide}`}
              className="btn btn-circle btn-outline"
            >
              ❮
            </a>
            <a
              href={`#slide${nextSlide}`}
              className="btn btn-circle btn-outline"
            >
              ❯
            </a>
          </div>
        )) ||
          ""}
      </div>
    </div>
  );
};

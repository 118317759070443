import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Layout from "./Layout";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ErrorPage } from "./ErrorPage";
import { Landing } from "./landing/LandingPage";
import { AboutPage } from "./about/AboutPage";
import { CaseStudies } from "./case_studies/CaseStudiesPage";
import { Contact } from "./contact/contactpage";
import { AigorPage } from "./case_studies/AigorPage";
import { CircaDiaPage } from "./case_studies/CircaDiaPage";
import { VelevPage } from "./case_studies/VelevPage";
import { MerbestPage } from "./case_studies/MerbestPage";
import GreteTaimre from "./PDFViewer";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Landing />,
      },
      {
        path: "./GreteTaimreCV",
        element: <GreteTaimre />,
      },
      {
        path: "/About",
        element: <AboutPage />,
      },

      {
        path: "/Casestudies",
        element: <CaseStudies />,
      },
      {
        path: "/Aigor",
        element: <AigorPage />,
      },
      {
        path: "/Circadia",
        element: <CircaDiaPage />,
      },
      {
        path: "/Velev",
        element: <VelevPage />,
      },
      {
        path: "/Contact",
        element: <Contact />,
      },
      {
        path: "/Merbest",
        element: <MerbestPage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
    <meta
      name="keywords"
      content="grete taimre, ux designer, ui designer, grete taimre portfolio, grete taimre case studies, grete taimre contact"
    />
  </React.StrictMode>
);
